import axios from 'axios';
import token from './authToken';

// TEST URL
// const lambUrl = 'https://folqp39skj.execute-api.eu-west-2.amazonaws.com/default/neodisha-fashion-webapp';

// PROD URL
const lambUrl = 'https://wfsajq7upd.execute-api.ap-south-1.amazonaws.com/prod/neodisha-fashion-webapp';

export const GetCaptureProgress = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard_capture_progress`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling GetCaptureProgress API: ', error);
  }
};

export const GetSevenDaysCapProgress = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/store-view/get_seven_days_cap_progress`, data, {
      headers: {
        Accept: 'application/json'
        // Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling GetCaptureProgress API: ', error);
  }
};

export const GetSevenDaysOSA = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/store-view/get_baywise_osa_seven_days`, data, {
      headers: {
        Accept: 'application/json'
        // Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling GetCaptureProgress API: ', error);
  }
};

export const GetAssociateScore = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/store-view/associate_score`, data, {
      headers: {
        Accept: 'application/json'
        // Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling GetCaptureProgress API: ', error);
  }
};

export const GetCategoryWiseAnomaly = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/store-view/get_baywise_category_anomaly`, data, {
      headers: {
        Accept: 'application/json'
        // Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling GetCaptureProgress API: ', error);
  }
};

export const UploadStoreData = async (data) => {
  try {
    const res = await axios.post(
      `https://folqp39skj.execute-api.eu-west-2.amazonaws.com/default/neodisha-fashion-webapp/upload_images`,
      data,
      {
        headers: {
          Accept: 'application/json',
          Authorization: await token()
        }
      }
    );
    return res;
  } catch (error) {
    console.log('Error Calling UploadStoreData API: ', error);
  }
};

export const UploadEditStoreData = async (data) => {
  try {
    const res = await axios.put(`http://localhost:8081/data/updatestore/6640c3acfa31683c6888738d`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling UploadEditStoreData API: ', error);
  }
};

export const GetStoreData = async (data) => {
  try {
    const res = await axios.post(`http://localhost:8081/data/getstore`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling GetStoreData API: ', error);
  }
};

export const dates = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/store/calender`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling Event dates API: ', error);
  }
};

export const GetBrandDonutData = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard/doughtnut/brand_wise_fullness`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling GetBrandDonutData API: ', error);
  }
};

export const GetFullnessKpi = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard_card_fullness`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    console.log('fullness res', res);
    return res;
  } catch (error) {
    console.log('Error Calling GetFullnessKpi API: ', error);
  }
};

export const GetAnomaliesKpi = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard/card_anomalies`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    console.log('anomaliesData', res);
    return res;
  } catch (error) {
    console.log('Error Calling GetAnomaliesKpi API: ', error);
  }
};

export const GetAnomaliesBarChartData = async (data) => {
  try {
    console.log('adft', data);
    const res = await axios.post(`${lambUrl}/dashboard/anomalies_barchart`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling GetAnomaliesKpi API: ', error);
  }
};

// API FOR THE LAYOUT OF THE STORE
export const GetStoreLayout = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/store/analysis`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    console.log('api response', res);
    return res;
  } catch (error) {
    console.log('Error Calling all Store API: ', error);
  }
};
// API FOR THE LAYOUT OF THE STORE
export const GetImagesFromSignedUrl = async (data) => {
  try {
    const res = await axios.post(`https://wfsajq7upd.execute-api.ap-south-1.amazonaws.com/prod/neodisha-fashion-app/signed_image`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    // console.log("api image response", res);
    return res;
  } catch (error) {
    console.log('Error Calling all Store API: ', error);
  }
};

export const getUsers = async (data) => {
  try {
    const res = await axios.post(
      // `https://folqp39skj.execute-api.eu-west-2.amazonaws.com/default/neodisha-fashion-webapp/team/getalluser`,
      `${lambUrl}/team/getalluser`,
      data,
      {
        headers: {
          Accept: 'application/json',
          Authorization: await token()
        }
      }
    );
    // console.log("api image response", res);
    return res;
  } catch (error) {
    console.log('Error while calling team API :', error);
  }
};

export const getOneUser = async (id) => {
  try {
    const res = await axios.get(`${lambUrl}/team/get_one_user?userID=${id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    // console.log("api image response", res);
    return res;
  } catch (error) {
    console.log('Error while calling team api :', error);
  }
};

export const deleteUser = async (id) => {
  try {
    const res = await axios.get(
      `https://folqp39skj.execute-api.eu-west-2.amazonaws.com/default/neodisha-fashion-webapp/team/delete_user?userID=${id}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: await token()
        }
      }
    );
    // console.log("api image response", res);
    return res;
  } catch (error) {
    console.log('Error while calling team api :', error);
  }
};

export const updateUser = async (id, user) => {
  try {
    const res = await axios.post(`${lambUrl}/team/update_user?userID=${id}`, user, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    // console.log("api image response", res);
    return res;
  } catch (error) {
    console.log('Error while calling team api :', error);
  }
};

export const createUser = async (user) => {
  try {
    const res = await axios.post(
      `https://folqp39skj.execute-api.eu-west-2.amazonaws.com/default/neodisha-fashion-webapp/team/create`,
      user,
      {
        headers: {
          Accept: 'application/json',
          Authorization: await token()
        }
      }
    );
    // console.log("api image response", res);
    return res;
  } catch (error) {
    console.log('Error while calling team api :', error);
  }
};

export const GetVerifiedUsers = async (phoneNumber) => {
  try {
    const res = await axios.get(
      `https://folqp39skj.execute-api.eu-west-2.amazonaws.com/default/neodisha-fashion-app/user/auth_user_cred?numbers=${phoneNumber}`,
      {
        headers: {
          Accept: 'application/json'
        }
      }
    );
    // console.log(res.data);
    return res;
  } catch (error) {
    console.log('Error Calling users API: ', error);
  }
};

export const GetVMCompliance = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard/vmc_fullness`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling GetVMCompliance API: ', error);
  }
};

export const GetVMComplianceForOneWeek = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard/timeseries_vmc`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    console.log('result', res);
    return res;
  } catch (error) {
    console.log('Error calling vm compliance for a week api', error);
  }
};

export const GetFullnessForOneWeek = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard/timeseries_fullness`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error calling fullness for a week api', error);
  }
};

export const GetAnomalyDetails = async (data) => {
  try {
    const res = await axios.get(`${lambUrl}/store/get_analysis_data?analysisID=${data}`, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling GetAnomalyDetails API: ', error);
  }
};

export const GetAnomaliesForOneWeek = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard/timeseries_anomalies`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error calling anomalies for a week api', error);
  }
};

export const checkId = async (user_id) => {
  try {
    const res = await axios.get(`${lambUrl}/team/check_users?user_id=${user_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res.data;
  } catch (error) {
    console.log('Error');
    throw error;
  }
};

export const allStoresId = async () => {
  try {
    const res = await axios.get(
      `https://wfsajq7upd.execute-api.ap-south-1.amazonaws.com/prod/neodisha-fashion-webapp/store/get_all_store`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: await token()
        }
      }
    );
    return res.data;
  } catch (error) {
    console.log('Error occured');
    throw error;
  }
};

export const GetBarChartData = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard/bay_wise_fullness`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    console.log('bar chart data', res);
    return res;
  } catch (error) {
    console.log('Error calling bar chart api', error);
  }
};

export const GetVMscoreBar = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard/vmc-barchart`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    console.log('vmc data', res);
    return res;
  } catch (error) {
    console.log('Error calling bar chart api', error);
  }
};

export const GetStoreWiseInfo = async (date, store_id) => {
  try {
    const res = await axios.get(`${lambUrl}/store/stores_wise_config?date=${date}&store_id=${store_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    console.log('hhh', res);
    return res;
  } catch (error) {
    console.log('Error calling store wise info', error);
  }
};
export const GetAllStoresInfo = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/store/stores_wise_config`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error calling store wise info', error);
  }
};
export const GetBayWiseDetails = async (date, store) => {
  try {
    const res = await axios.get(`${lambUrl}/store/bay_wise_config?date=${date}&store_id=${store}`, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling bay wise details API: ', error);
  }
};

export const GetShelfWiseDetails = async (date, bay_id) => {
  try {
    const res = await axios.get(`${lambUrl}/store/shelf_wise_config?date=${date}&bay_id=${bay_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error calling shelf-wise details', error);
  }
};

export const GetPartsWiseDetails = async (date, shelf_id) => {
  try {
    const res = await axios.get(`${lambUrl}/store/parts_wise_config?date=${date}&shelf_id=${shelf_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error calling parts-wise details', error);
  }
};

export const UpdateMetadataStatus = async (id, comments) => {
  try {
    const res = await axios.get(`${lambUrl}/store/update?metadata_id=${id}&comments=${comments}`, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    console.log('api image response', res);
    return res;
  } catch (error) {
    console.log('Error while calling team api :', error);
  }
};

export const UpdateMetadataStatusIgnore = async (id, ignored) => {
  try {
    const res = await axios.get(`${lambUrl}/store/update_ignored?metadata_id=${id}&ignored=${ignored}`, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    console.log('api image response', res);
    return res;
  } catch (error) {
    console.log('Error while calling team api :', error);
  }
};

export const SendAlert = async (data, apiKey, apiUrl) => {
  console.log('SendAlert', data);
  try {
    const response = await axios.post(apiUrl, data, {
      headers: {
        Authorization: `Bearer ${apiKey}`
      }
    });

    console.log('API Call Successful (Aisensy):', response.data);
    return true;
  } catch (error) {
    console.error('API Call Failed:', error.message);
    return false;
  }
};

export const GetNeodishaVerifiedUser = async (data) => {
  try {
    const res = await axios.post(
      'https://wfsajq7upd.execute-api.ap-south-1.amazonaws.com/prod/neodisha-fashion-webapp/get-user-detail-beauty',
      data,
      {
        headers: {
          Accept: 'application/json',
          Authorization: await token()
        }
      }
    );
    return res;
  } catch (error) {
    console.log('Error calling neodisha verified user', error);
  }
};

export const GetClusterManager = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard_get_cluster_managers`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    console.log('datasss', res.data);
    return res;
  } catch (error) {
    console.log('Error calling neodisha cluster manager', error);
  }
};

export const GetZones = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard_get_zone`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    console.log('datasss2', res.data);
    return res;
  } catch (error) {
    console.log('Error calling neodisha cluster manager', error);
  }
};

export const GetStates = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard_get_state`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    console.log('datasss3', res.data);
    return res;
  } catch (error) {
    console.log('Error calling neodisha cluster manager', error);
  }
};

export const GetCities = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard_get_cities`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    console.log('datasss4', res.data);
    return res;
  } catch (error) {
    console.log('Error calling neodisha cluster manager', error);
  }
};

export const GetBrands = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard_get_brands`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    console.log('datasss5', res.data);
    return res;
  } catch (error) {
    console.log('Error calling neodisha cluster manager', error);
  }
};

export const GetPartAndAnalysisData = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/store/update-reference-img`, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: await token()
      }
    });

    return res.data;
  } catch (error) {
    console.error('Error calling getPartAndAnalysisData API:', error);
  }
};

export const GetBayWiseAnomalies = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/store-view/get_baywise_anomaly`, data, {
      headers: {
        Accept: 'application/json'
        // Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling GetCaptureProgress API: ', error);
  }
};

export const GetBayWiseOSA = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/store-view/get_baywise_osa`, data, {
      headers: {
        Accept: 'application/json'
        // Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling GetCaptureProgress API: ', error);
  }
};

export const GetBayWiseVM = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/store-view/get_baywise_vm`, data, {
      headers: {
        Accept: 'application/json'
        // Authorization: await token()
      }
    });
    return res;
  } catch (error) {
    console.log('Error Calling GetCaptureProgress API: ', error);
  }
};
export const GetOsaForAll = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard/blush_lace_osa_score`, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: await token()
      }
    });

    return res.data;
  } catch (error) {
    console.error('Error calling getPartAndAnalysisData API:', error);
  }
};

export const GetAnomalyForAll = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/dashboard/anomalies_card`, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: await token()
      }
    });

    return res.data;
  } catch (error) {
    console.error('Error calling getPartAndAnalysisData API:', error);
  }
};

export const getReference = async (dates, ids) => {
  try {
    const data = {
      dates: dates,
      ids: ids
    };
    const res = await axios.post(`${lambUrl}/store/shelf_reference_image`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res.data;
  } catch (error) {
    throw new Error(`Error Calling GetCaptureProgress API: ${error.message}`);
  }
};
export const UploadBulkUser = async (data) => {
  try {
    const res = await axios.post(
      `https://folqp39skj.execute-api.eu-west-2.amazonaws.com/default/neodisha-fashion-webapp/team/bulk_user_upload`,
      data,
      {
        headers: {
          Accept: 'application/json',
          Authorization: await token()
        }
      }
    );
    return res;
  } catch (error) {
    throw new Error(`Error Calling GetCaptureProgress API: ${error.message}`);
  }
};
export const ExportStoreData = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/store/store-export-analysis`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res.data;
  } catch (error) {
    throw new Error(`Error Calling GetCaptureProgress API: ${error.message}`);
  }
};
export const ExportBrandReferenceData = async (data) => {
  try {
    const res = await axios.post(`${lambUrl}/store/report/brand_reference`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: await token()
      }
    });
    return res.data;
  } catch (error) {
    throw new Error(`Error Calling GetCaptureProgress API: ${error.message}`);
  }
};

export const getAnomalyBays = async (data) => {
  try {
    const res = await axios.post(
      `https://folqp39skj.execute-api.eu-west-2.amazonaws.com/default/neodisha-fashion-app/dashboard/get-anomaly-bay`,
      data,
      {
        headers: {
          Accept: 'application/json',
          Authorization: await token()
        }
      }
    );
    return res.data;
  } catch (error) {
    throw new Error(`Error Calling getAnomalyBays API: ${error.message}`);
  }
};
export const getBayAnomalyList = async (data) => {
  try {
    const res = await axios.post(
      ` https://folqp39skj.execute-api.eu-west-2.amazonaws.com/default/neodisha-fashion-app/dashboard/get-baywise-anomaly-list`,
      data,
      {
        headers: {
          Accept: 'application/json',
          Authorization: await token()
        }
      }
    );
    return res.data;
  } catch (error) {
    throw new Error(`Error Calling getAnomalyBays API: ${error.message}`);
  }
};
